<template>
	<v-layout column fill-height>
		<v-flex v-if="canCreate" shrink text-xs-center>
			<v-tooltip v-if="mini" bottom>
				<template v-slot:activator="{ on }">
					<v-btn color="primary" icon size="2.5em" v-on="on" @click="openCreationDialog({})">
						<v-icon small>add</v-icon>
					</v-btn>
				</template>
				<span>{{ $t('admin_group.manager.create_accounting_firm') }}</span>
			</v-tooltip>
			<w-btn v-else icon="add" @click="openCreationDialog()">{{ $t('admin_group.manager.create_accounting_firm') }}</w-btn>
			<w-dialog v-model="dialog" max-width="500px" :title="$t('admin_group.manager.create_accounting_firm')">
				<AccountingFirmForm v-model="newAccountingFirm" :show-domain="false" />
				<template v-slot:actions>
					<w-layout justify-end>
						<w-btn-save @click="createAccountingFirm()" />
					</w-layout>
				</template>
			</w-dialog>
		</v-flex>
		<v-flex scroll-y>
			<v-list :dense="mini">
				<v-list-tile
					v-for="accountingFirm in accountingFirmsList"
					:key="accountingFirm.id"
					active-class="primary--text"
					avatar
					:value="accountingFirmSelected && accountingFirm.id == accountingFirmSelected.id"
					@click="selectAccountingFirm(accountingFirm)"
				>
					<v-list-tile-avatar>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-avatar color="primary" size="2.5em" v-on="on">
									<v-flex white--text v-text="accountingFirm.abbreviation" />
								</v-avatar>
							</template>
							<span v-text="accountingFirm.name" />
						</v-tooltip>
					</v-list-tile-avatar>
					<v-list-tile-content>
						<v-list-tile-title :class="accountingFirm.deleted_at ? 'crossed--text' : ''" v-text="accountingFirm.name" />
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import GroupModuleGuard from '@/mixins/ModulesGuards/Group/GroupModuleGuard'

export default {
	name: 'AccountingFirmsList',
	components: {
		AccountingFirmForm: () => ({
			component: import('@/components/Group/Manager/AccountingFirmForm')
		})
	},
	mixins: [GroupModuleGuard],
	data: function () {
		return {
			canCreate: true,
			canShowDeactivated: true,
			dialog: false,
			mini: false,
			newAccountingFirm: {}
		}
	},
	computed: {
		...mapState({
			accountingFirmSelected: state => state.group.selected,
			accountingFirm: state => state.accountingFirm.selected,
			accountingFirms: state => state.group.list
		}),
		accountingFirmsList: function () {
			let result = []
			if (this.canShowDeactivated) {
				result = this.accountingFirms
			} else {
				result = this.accountingFirms.filter(item => !item.deleted_at)
			}
			return result
		}
	},
	created: function () {
		this.service.getAccountingFirms()
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		createAccountingFirm: function () {
			const data = {
				...this.newAccountingFirm,
				holding_id: this.accountingFirm.holding_id
			}
			this.service.createAccountingFirm(data).then(() => {
				this.newAccountingFirm = {}
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('admin_group.accounting_firm_created'))
				this.dialog = false
			})
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		openCreationDialog: function () {
			this.dialog = true
		},
		selectAccountingFirm: function (accountingFirm) {
			this.service.selectAccountingFirm(accountingFirm)
		}
	}
}
</script>
