<template>
	<GroupManagerForm />
</template>

<script>
import Vue from 'vue'
import GroupModuleGuard from '@/mixins/ModulesGuards/Group/GroupModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import AccountingFirmsList from '@/components/Group/AccountingFirmsList'

export default {
	name: 'GroupManager',
	components: {
		GroupManagerForm: () => ({
			component: import('@/components/Group/Manager/GroupManagerForm')
		})
	},
	mixins: [
		GroupModuleGuard,
		HandleSideBarMixin,
	],
	watch: {
		accountingFirmId: function () {
			if (!this.service.adminGroup()) {
				this.appService.goTo('accounting-firm-settings')
			}
		}
	},
	created: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		const AccountingFirmsListClass = Vue.extend(AccountingFirmsList)
		const accountingFirmsListComponent = new AccountingFirmsListClass({
			parent: this
		})
		this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
			canClose: this.$vuetify.breakpoint.smAndDown,
			canMinimize: this.$vuetify.breakpoint.mdAndUp,
			emitter: this,
			moduleInstance: accountingFirmsListComponent,
			startHidden: this.$vuetify.breakpoint.smAndDown
		})
		this.setHasSidebar(true)
		this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp)
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	}
}
</script>
